import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { LumaSplatsThree } from '@lumaai/luma-web';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const LumaComponent = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        const mount = mountRef.current;
        const width = mount.clientWidth;
        const height = mount.clientHeight;
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(width, height);
        mount.appendChild(renderer.domElement);

        const lumaSplats = new LumaSplatsThree({
            source: "https://lumalabs.ai/capture/160202cc-0260-4f88-bbcb-cefba3d9af37",
            enableThreeShaderIntegration: false,
        });
        
        // Configure lumaSplats as needed
        scene.add(lumaSplats);

        // lumaSplats.onInitialCameraTransform = transform => {
        //     transform.decompose(camera.position, camera.quaternion, new THREE.Vector3());
        // };
        camera.position.set(-0.09406418514501089, 0.5490779091349136, 2.945175717219275);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.update(); // Only required if controls.enableDamping is set to true

        const animate = () => {
            requestAnimationFrame(animate);
            controls.update(); // Only required if controls.enableDamping is set to true
            renderer.render(scene, camera);
        };

        animate();

        return () => {
            if (mount) {
                mount.removeChild(renderer.domElement);
            }
        };
    }, []);

    return <div ref={mountRef} className="luma" style={{ width: window.innerWidth, height: window.innerWidth / 3, minHeight: '400px' }} />;
};

export default LumaComponent;
