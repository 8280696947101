import React, { useState } from 'react';
import './App.css';
import LumaComponent from './LumaComponent';


function App() {
  const [showLuma, setShowLuma] = useState(false);
  
  return (
    <div >
      <body className="App">
        <div className="App-body">
          <div className="Body-surname">The Watters Family</div>
          <div className="Body-title">Maple Row Farms</div>
          <div className="Body-established-container">
            <div className="line"></div>
            <p className="Body-established-text">Established 1855</p>
            <div className="line"></div>
          </div>

          <div className="Body-address">210 Watters Road, Port Murray, NJ</div>
          
          <div className="take-a-look-container">
            {!showLuma && (
              <button className="button-53" onClick={() => setShowLuma(true)} >Take a Look</button>
            )}
            
            {showLuma && (
              <div className="popover">
                <LumaComponent />
              </div>
            )}
          </div>
        </div>
      </body>
    </div>
  );
}

export default App;
